import React, { useEffect, useState } from "react";
import Img from "gatsby-image";
import { FixedObject, FluidObject } from "gatsby-image/index";
import cn from "classnames";
import useBodyScrollLock from "@/plugins/useBodyScrollLock";
import Modal from "@/components/kit/modal";
import ClientOnly from "@/utils/ClientOnly";
import * as css from "./art-director-button.modules.scss";
import { useCallback } from "react";

type ArtDirectorButtonProps = {
  position: string;
  name: string;
  cover?: {
    fluid: FluidObject;
  };
  text?: {
    childMarkdownRemark: {
      html: string;
    };
  };
  previewCover: {
    fixed: FixedObject;
  };
};

const ArtDirectorButton: React.FC<ArtDirectorButtonProps> = ({
  previewCover,
  position,
  name,
  cover,
  text,
}) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [setLock] = useBodyScrollLock();

  useEffect(() => {
    setLock(isShowModal);
  }, [isShowModal]);

  const openModal = useCallback(() => setIsShowModal(true), []);
  const closeModal = useCallback(() => setIsShowModal(false), []);

  return (
    <>
      <button className={cn(css.avatar, "relative")} onClick={openModal}>
        <Img {...previewCover} loading={"eager"} />
      </button>

      <ClientOnly>
        <Modal isShow={isShowModal} close={closeModal}>
          <div className={css.modalContent} key={name}>
            {position && (
              <div className="text-title-1 uppercase pb-1 border-b-2 border-solid desktop:pb-2">
                {position}
              </div>
            )}

            <h2 className="mt-6 tablet:w-1/2 desktop:w-full">{name}</h2>

            <div
              className={
                "mt-7 tablet:flex desktop:block desktop:mt-6 desktop1280:mt-8 desktop1440:mt-11"
              }
            >
              {cover && (
                <Img
                  className={
                    "-mx-4 " +
                    "tablet:mx-0 tablet:flex-1 tablet:min-w-0 " +
                    "desktop:-mx-8 " +
                    "desktop1280:-mx-10 " +
                    "desktop1440:-mx-11"
                  }
                  loading={"eager"}
                  {...cover}
                />
              )}

              <div className={"tablet:w-1/24"} />

              {text && (
                <div
                  className={cn(
                    css.plain,
                    "mt-6 tablet:w-1/2 tablet:mt-0 " +
                      "desktop:mt-6 desktop:w-full " +
                      "desktop1280:mt-7 desktop1440:mt-9"
                  )}
                  dangerouslySetInnerHTML={{
                    __html: text.childMarkdownRemark.html,
                  }}
                />
              )}
            </div>
          </div>
        </Modal>
      </ClientOnly>
    </>
  );
};

export default ArtDirectorButton;
