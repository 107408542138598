import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import cn from "classnames";
import PageTitle from "@/components/kit/page-title";
import Button from "@/components/kit/button";
import * as styleConstants from "@/constants/style";
import { useRoute } from "@/utils";
import Carousel from "@/components/carousel";
import ArtDirectorButton from "@/components/art-director-button";
import Wysiwyg from "@/components/kit/wysiwyg";
import { AniLink } from "@/components/global-navigation";

const About = ({ data }) => {
  const route = useRoute();
  const { layoutFestival } = data;

  return (
    <div
      className={
        "mb-8 desktop:mb-28 desktop:pt-10 desktop1280:pt-12 desktop1440:pt-14"
      }
    >
      <PageTitle>{layoutFestival.title}</PageTitle>

      <section
        className={cn(
          styleConstants.container,
          "py-5 desktop:py-7 desktop1280:py-9 desktop:1440:py-10 tablet:flex"
        )}
      >
        <div
          className={
            "tablet:order-3 tablet:w-2/3 desktop:flex-1 desktop:min-w-0"
          }
        >
          <div>
            {Array.isArray(layoutFestival?.carousel) && (
              <Carousel className={"px-0 py-0"}>
                {layoutFestival.carousel.map((item, i) => (
                  <Img key={i} {...item} loading={i === 0 ? "eager" : "auto"} />
                ))}
              </Carousel>
            )}
          </div>

          {layoutFestival?.text?.childMarkdownRemark?.html && (
            <Wysiwyg
              className={cn(
                "border-t-2 border-b-2 border-solid pt-5 pb-6 mt-10 text-big " +
                  "tablet:mt-11 tablet:pt-0 tablet:pb-0 tablet:border-0 " +
                  "desktop:mt-10 desktop1280:mt-11 desktop1440:mt-12"
              )}
            >
              {layoutFestival.text.childMarkdownRemark.html}
            </Wysiwyg>
          )}
        </div>

        <div className={"desktop:order-2 desktop:w-1/24"} />

        <aside
          className={
            "mt-6 tablet:order-1 tablet:w-1/3 tablet:mt-0 desktop:w-1/4"
          }
        >
          <div className={"tablet:w-7/8 desktop:w-full"}>
            {layoutFestival.gotoArchiveButton && (
              <Button className="w-full" is={AniLink} to={route("archive")}>
                {layoutFestival.gotoArchiveButton}
              </Button>
            )}

            {layoutFestival.gotoArchiveButtonSubtitle && (
              <div className={"text-center mt-2 tablet:text-left"}>
                {layoutFestival.gotoArchiveButtonSubtitle}
              </div>
            )}

            <div
              className={cn(
                "mt-6 pt-6 border-t-2 border-solid text-center " +
                  "tablet:text-left tablet:pt-0 tablet:border-t-0 " +
                  "desktop:mt-8 desktop1280:mt-11 desktop1440:mt-12"
              )}
            >
              {layoutFestival.artsDirectorsTitle && (
                <div className={"uppercase text-title-2"}>
                  {layoutFestival.artsDirectorsTitle}
                </div>
              )}

              {Array.isArray(layoutFestival.artDirectors) && (
                <div className={"text-normal mt-1 desktop1280:mt-2"}>
                  {layoutFestival.artDirectors.map((i, index) => (
                    <p key={index}>{i.name}</p>
                  ))}
                </div>
              )}

              {Array.isArray(layoutFestival.artDirectors) && (
                <div
                  className={"mt-4 flex justify-center tablet:justify-start"}
                >
                  {layoutFestival.artDirectors.map((item, i) => (
                    <ArtDirectorButton key={i} {...item} />
                  ))}
                </div>
              )}

              {layoutFestival.teamTitle && (
                <div
                  className={
                    "mt-4 uppercase text-title-2 tablet:mt-6 desktop:mt-8 desktop1280:mt-11"
                  }
                >
                  {layoutFestival.teamTitle}
                </div>
              )}

              <div
                className={"whitespace-pre-wrap text-normal mt-2"}
                dangerouslySetInnerHTML={{
                  __html: layoutFestival.team.join("\n"),
                }}
              />
            </div>
          </div>
        </aside>
      </section>
    </div>
  );
};

export default About;

export const aboutPageFragment = graphql`
  fragment FestivalPageFragment on ContentfulLayoutFestival {
    title
    gotoArchiveButton
    gotoArchiveButtonSubtitle
    text {
      childMarkdownRemark {
        html
      }
    }
    carousel {
      fluid(maxWidth: 1600) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    artsDirectorsTitle
    artDirectors {
      name
      position
      text {
        childMarkdownRemark {
          html
        }
      }
      cover {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      previewCover {
        fixed(width: 180, quality: 100) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
    }
    teamTitle
    team
    ogDescription {
      ogDescription
    }
    ogImage {
      file {
        url
        details {
          image {
            height
            width
          }
        }
      }
    }
  }
`;
