import React, { useMemo } from "react";
import cn from "classnames";
import typografFn from "@/plugins/typograf";
import isEqual from "react-fast-compare";
import * as css from "./style.modules.scss";

type WysiwygProps = {
  className?: string;
  useTypograph?: boolean;
  defaultStyles?: boolean;
};

function isString(x: unknown): x is string {
  return typeof x === "string";
}

const Wysiwyg: React.FC<WysiwygProps> = React.memo(
  ({
    className,
    children,
    useTypograph = true,
    defaultStyles = true,
    ...rest
  }) => {
    const childrenIsString = isString(children);

    if (isString(children)) {
      children = children.replace(/\n/g, "<br/>");
    }

    const renderProps:
      | { children: React.ReactNode }
      | { dangerouslySetInnerHTML?: { __html: string } } = useMemo(
      () =>
        childrenIsString
          ? {
              dangerouslySetInnerHTML: children
                ? {
                    __html: useTypograph
                      ? typografFn(children as string)
                      : (children as string),
                  }
                : undefined,
            }
          : { children },
      [childrenIsString, children, useTypograph]
    );

    return (
      <div
        className={cn({ [css.root]: defaultStyles }, className)}
        {...rest}
        {...renderProps}
      />
    );
  },
  isEqual
);

export default Wysiwyg;
