import React from "react";
import { Portal } from "react-portal";
import cn from "classnames";
import Button from "@/components/kit/button";
import * as css from "./modal.modules.scss";
import useBodyScrollLock from "@/plugins/useBodyScrollLock";
import { useI18n } from "@/plugins/i18n";

const Modal = ({ className = "", close = () => {}, isShow, children }) => {
  const { t } = useI18n();
  const [lock, innerScrollRef] = useBodyScrollLock();

  const CLOSE_TEXT = t("close");

  React.useEffect(() => {
    lock(isShow);
  }, [isShow]);

  return (
    <Portal>
      <div className={cn(css.frame, className, { show: isShow }, "relative")}>
        <div
          className={cn(css.layer, "fixed top-0 left-0 right-0 bottom-0")}
          onClick={close}
        />

        <div
          className={cn(
            css.modal,
            "flex flex-col fixed top-0 right-0 bottom-0"
          )}
        >
          <div
            ref={innerScrollRef}
            className={
              "relative flex-1 min-h-0 overflow-y-auto overflow-x-hidden px-4 pt-8 " +
              "tablet:px-5 tablet:pt-12 " +
              "desktop:px-8 desktop:pt-12 " +
              "desktop1280:px-10 desktop1280:pt-17 " +
              "desktop1440:px-11 desktop1440:pt-19 "
            }
          >
            <button
              className={cn(
                css.close,
                "hidden tablet:block absolute top-0 right-0 mt-7 mr-5 " +
                  "desktop:mr-8 desktop1280:mt-9 desktop1280:mr-10 " +
                  "desktop1440:mt-10 desktop1440:mr-11"
              )}
              onClick={close}
            />

            <div className="pb-8">{children}</div>
          </div>

          <div className={cn(css.footer, "tablet:hidden flex-shrink-0 pb-3")}>
            <Button className={"w-full"} onClick={close} noHover={true}>
              {CLOSE_TEXT}
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
