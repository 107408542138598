import React from "react";
import About from "@/components/pages/about";
import { graphql } from "gatsby";
import SEOEn from "@/components/seo/index.en";
import SEORu from "@/components/seo/index.ru";

const AboutEn = (props) => {
  const { pageContext } = props;

  return (
    <>
      {pageContext.langKey === "en" && (
        <SEOEn
          title={props.data.layoutFestival.title}
          description={props.data.layoutFestival.ogDescription?.ogDescription}
          ogImage={props.data.layoutFestival.ogImage}
        />
      )}
      {pageContext.langKey === "ru" && (
        <SEORu
          title={props.data.layoutFestival.title}
          description={props.data.layoutFestival.ogDescription?.ogDescription}
          ogImage={props.data.layoutFestival.ogImage}
        />
      )}
      <About {...props} />
    </>
  );
};

export const pageQuery = graphql`
  query($locale: String!) {
    layoutFestival: contentfulLayoutFestival(node_locale: { eq: $locale }) {
      ...FestivalPageFragment
    }
  }
`;

export default AboutEn;
